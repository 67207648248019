<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/img/logo-white.png">
    <!-- <h1>Surreal Multimídia #FAAC0C</h1> -->
  </div>
</template>

<script>

export default {
  name: 'Home'
}
</script>

<style lang="sass" scoped>
.home
  background-image: linear-gradient(to bottom right, #FAAC0C, #E07020)
  background-color: #E08321
  color: white
  height: 100vh
  display: flex
  align-items: center
  justify-content: center

</style>
